<script>
import Layout from "../../layouts/auth";
import Repository from "@/app/repository/repository-factory";
import router from "@/router/routes";
const RodoRepository = Repository.get("RodoRepository");

export default {
  components: {Layout},
  data() {
    return {
      form: {
        email: ""
      },
      iconPath: require(process.env.VUE_APP_ICON_PATH),
      orgName: process.env.VUE_APP_ORG_NAME,
    };
  },
  computed: {},
  methods: {
    acceptRodo() {
      RodoRepository.acceptRodo().then(() => {
        let localUser = JSON.parse(localStorage.getItem('user'));
        localUser.rodoAccepted = true;
        localStorage.setItem('user', JSON.stringify(localUser));

        const lastItem = localUser.accessToSubaccount[Object.keys(localUser.accessToSubaccount)[0]];
        router.push('/subaccount/profile/' + lastItem.subaccountUuid);
      });
    }
  }
};
</script>

<template>
  <Layout>
    <div class="row justify-content-center">
      <b-jumbotron style="padding-top: 20px; padding-bottom: 25px;">
        <template #header>Klauzula informacyjna (RODO)</template>
        <template #lead>
          <br>
          <p>
            <i class="fas fa-info-circle text-primary" style="padding-right: 10px;"></i>
            <strong>Aby przejść dalej, zapoznaj się z treścią klauzuli i zaakceptuj ją</strong>
            <i class="fas fa-angle-down text-primary" style="padding-left: 10px;"></i>
          <p>
          Na podstawie art. 13 ust. 1 i 2 Rozporządzenia Parlamentu Europejskiego i Rady (UE) 2016/679 z 27 kwietnia
          2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie
          swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (Dz.U.UE.L.2016.119.1 z dnia
            2016.05.04) („RODO”) informuję, że:
          </p>
        </template>

        <hr class="my-4">

        <div style="font-size: 15px;">
          <p><strong>1. Administrator danych osobowych</strong></p>
          <p>
            Administratorem Pani/Pana i Pani/Pana Dziecka danych osobowych jest Fundacja ZOBACZ MNIE z siedzibą
            we Wrocławiu przy ul. Ofiar Oświęcimskich 14/11, 50-069 Wrocław adres e-mail: fundacja@zobaczmnie.org,
            wpisana przez Sąd Rejonowy dla Wrocławia – Fabrycznej VI Wydział Gospodarczy Krajowego Rejestru
            Sądowego do Krajowego Rejestru Sądowego pod numerem 0000795364, numer NIP: 897-18-68-716, REGON:
            383909908.
          </p>

          <p><strong>2. Cele i podstawy prawne przetwarzania danych osobowych</strong></p>
          <p>Pani/Pana dane osobowe będą przetwarzane w celach:</p>
          <ul>
            <li>
              realizacji umowy o prowadzenie Konta Imiennego dla Podopiecznego Fundacji – na podstawie
              art. 6 ust. 1 lit. b RODO,
            </li>
            <li>
              realizacji obowiązków wynikających z przepisów prawa - w oparciu o przepisy prawa, w tym
              prawa podatkowego i prawa ubezpieczeń społecznych, m. in. na potrzeby prowadzenia
              księgowości, dokonywania przelewów, dokonywania rozliczeń należności publicznoprawnych
              i realizacji innych obowiązków publicznoprawnych – na podstawie art. 6 ust. 1 lit. c RODO w
              związku z art. 74 ust. 2 ustawy z dnia 29 września 1994 r. o rachunkowości,
            </li>
            <li>
              ochrony żywotnych interesów osoby, której dane dotyczą, lub innej osoby fizycznej (Pani /
              Pana Dziecka – Podopiecznego Fundacji) - w zakresie organizowania pomocy i pozyskiwania i
              gromadzenia funduszy oraz prowadzenia zbiórek publicznych - na podstawie art. 6 ust. 1 lit. d
              RODO i art. 9 ust. 1 lit. c RODO,
            </li>
            <li>
              realizacji prawnie uzasadnionego interesu Administratora - dochodzenia ewentualnych
              roszczeń bądź obrony przed takimi roszczeniami - na podstawie art. 6 ust. 1 lit. f RODO,
            </li>
            <li>
              informowania w środkach masowego przekazu, w tym mediach i na profilach
              społecznościowych Administratora o prowadzonej przez Administratora działalności oraz o
              zbiórkach publicznych prowadzonych przez Administratora lub z jego udziałem bądź za
              pośrednictwem na rzecz dziecka - Podopiecznego Fundacji – na podstawie art. 6 ust. 1 lit. a
              RODO – na podstawie odrębnej dobrowolnej zgody,
            </li>
            <li>
              w celach wskazanych w treści innych zgód na przetwarzanie danych osobowych – jeśli takie
              zgody były wyrażane – na podstawie art. 6 ust. 1 lit. a RODO.
            </li>
          </ul>

          <p><strong>3. Odbiorcy danych osobowych</strong></p>
          <p>Pani/Pana dane osobowe mogą zostać przekazane:</p>
          <ul>
            <li>
              podmiotom uprawnionym do uzyskania danych na podstawie odrębnych przepisów prawa
              (policja, sądy, prokuratura, ABW, CBA oraz inne publicznoprawne organy kontroli),
            </li>
            <li>
              podmiotom uprawnionym do uzyskania danych na podstawie odrębnych przepisów prawa
              celem realizacji obowiązków rachunkowych i księgowych oraz podatkowych Administratora
              (np. urząd skarbowy),
            </li>
            <li>
              dostawcom usług prawnych, księgowych, kadrowych, IT oraz innych usług o charakterze
              technicznym i organizacyjnym, niezbędnych dla prowadzonej przez Administratora
              działalności,
            </li>
            <li>
              bankom – w celu realizacji przelewów należności (w tym przelewów z Konta Imiennego),
            </li>
            <li>
              kontrahentom oraz podmiotom współpracującym z Administratorem – w zakresie koniecznym
              do realizacji przez Administratora obowiązków w zakresie prowadzenia Konta Imiennego oraz
              w zakresie prowadzonej przez Administratora działalności, mającej na celu pomoc Pani / Pana
              Dziecku – Podopiecznemu Fundacji.
            </li>
          </ul>
          <p>
            Co do zasady Pani / Pana i Pani/Pana Dziecka dane osobowe nie będą przekazywane odbiorcom z państw
            trzecich (spoza UE/EOG) ani organizacjom międzynarodowym.
          </p>
          <p>
            W wyjątkowych sytuacjach Pani / Pana i Pani/Pana Dziecka dane osobowe mogą być przekazane do
            odbiorców z krajów trzecich (poza EOG), przy czym w zależności od kraju do którego przekazywane będą
            dane, Administrator zapewni, by (1) w stosunku do takiego kraju obowiązywała ważna decyzja Komisji
            Europejskiej stwierdzająca, że państwo to zapewnia odpowiedni stopień ochrony danych osobowych (art. 45
            RODO) lub (2) zapewni zastosowanie standardowych klauzul umownych ochrony danych przyjętych przez
            Komisję Europejską (art. 47 RODO).
          </p>

          <p><strong>4. Okres przechowywania danych</strong></p>
          <p>
            Pani/Pana dane osobowe będą przechowywane przez okres 2 lat od zamknięcia Konta Imiennego, a po tym
            okresie:
          </p>

          <ul>
            <li>
              co do zasady - do upływu okresu przedawnienia roszczeń wynikających z przepisów Kodeksu
              cywilnego,
            </li>
            <li>
              dane zawarte w dokumentach podlegających obowiązkowej archiwizacji - do upływu okresu
              obowiązkowej archiwizacji,
            </li>
            <li>
              dane dotyczące realizacji obowiązków podatkowych i księgowych – 5 lat,
            </li>
            <li>
              w sytuacji gdy podstawą przetwarzania danych jest prawnie uzasadniony interes
              administratora lub dobrowolna zgody osoby, której dane dotyczą - do dnia wniesienia
              sprzeciwu lub cofnięcia zgody.
            </li>
          </ul>

          <p><strong>5. Prawa osób, których dane dotyczą</strong></p>
          <p>
            Przysługuje Pani / Panu prawo do:
          </p>
          <ul>
            <li>dostępu do danych osobowych,</li>
            <li>sprostowania danych osobowych,</li>
            <li>żądania usunięcia danych osobowych lub ograniczenia ich przetwarzania,</li>
            <li>prawo przenoszenia danych osobowych oraz wniesienia sprzeciwu co do ich przetwarzania,</li>
            <li>
              prawo wniesienia skargi do organu nadzorczego, jeżeli dane są przetwarzane niezgodnie z
              wymogami prawnymi. W Polsce organem tym jest Prezes Urzędu Ochrony Danych Osobowych,
            </li>
            <li>
              w przypadku danych osobowych przetwarzanych na podstawie udzielonej zgody – do
              wycofania zgody w dowolnym momencie. Cofnięcie zgody może nastąpić przez wysłanie
              informacji o cofnięciu na adres e-mail [ fundacja@zobaczmnie.org ] lub przez złożenie
              oświadczenia drogą pocztową na adres Fundacji lub w siedzibie Fundacji. Wycofanie zgody nie
              ma wpływu na zgodność z prawem przetwarzania danych osobowych dokonanego
              wycofaniem zgody.
            </li>
          </ul>

          <p><strong>6. Informacja o wymogu lub dobrowolności podania danych osobowych</strong></p>
          <p>
            Podanie przez Panią/Pana danych osobowych jest dobrowolne, jednakże konieczne do realizacji opisanego
            powyżej celu, tj. założenia, prowadzenia i obsługi Konta Imiennego dla Pani / Pana Dziecka – Podopiecznego
            Fundacji oraz w zakresie prowadzonej przez Administratora działalności, mającej na celu pomoc Pani / Pana
            Dziecku – Podopiecznemu Fundacji, a odmowa podania danych będzie skutkować niemożliwością założenia
            i prowadzenia oraz obsługi Konta Imiennego dla Pani / Pana Dziecka - Podopiecznego Fundacji i realizacji
            wskazanych obowiązków.
          </p>

          <p><strong>7. Zautomatyzowane podejmowanie decyzji</strong></p>
          <p>
            Pani/Pana dane nie będą podlegać zautomatyzowanemu podejmowaniu decyzji, w tym profilowaniu.
          </p>

        </div>
        <hr class="my-4">
        <div class="text-right">
          <b-button size="lg" v-on:click="acceptRodo()" variant="success" href="#"><i class="fas fa-check-circle"></i> Akceptuję zapisy RODO i przechodzę dalej</b-button>
        </div>

      </b-jumbotron>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>

<style lang="scss" module></style>
